<template>
    <div class=" full bgs_full relative" ref="home_box">
      <div class="full jian_bian_bgc">
        <!-- header -->
        <div class="header_bgi2 w_full h_100 bgs_full ph_20 border_box relative ">
          <div class="fw_bold fs_26 text_right ls_10 absolute left_center top_20 color_fff font_zh">
            向北开放重要桥头堡大数据平台
          </div> 

          <!-- search -->
          <div class="w_full h_100 flex_row_between flex_center">
            <div class="w_30_p flex_row_start flex_center">
              <div class="w_300">
                <el-input 
                  v-model="hsCode" 
                  size="small"
                  clearable placeholder="请输入HS编码" 
                  @keyup.enter.native="getTradeData(hsCode)" @clear="clearHs6Value"
                >
                  <el-button slot="append" icon="el-icon-search" @click="getTradeData(hsCode)"></el-button>
                </el-input>
              </div>
            </div>

            <div class="w_50_p text_right">
              <el-button size="small" @click="goToMyDsj2()">海关实用报关手册与税则查询</el-button>
              <!-- <el-button size="small" @click="goToPage('/china')"><span class="color_ce1126">中国</span>进出口总额Top10</el-button> -->
              <el-button size="small" class="ml_10" @click="goToPage('/russia/870324')">俄罗斯进出口总额</el-button>
            </div>
          </div>
        </div>

        <!-- body -->
        <div class="w_full-60 h_full-130 m_0_auto flex_row_between relative">
          <div class="w_600 h_full bgc_fbf9f8b5 border_radius_10 p_10">
            <div class="w_full flex_row_between">
              <div class="w_49_p bgc_fff border_radius_10 p_10">
                <div>我国2022年进口总额</div>
                <div class="text_center"> <span class="fs_30">{{ formatNumberWithCommas(chinaIed)}}</span> 美元</div>
              </div>

              <div class="w_49_p bgc_fff border_radius_10 p_10">
                <div>我国2022年出口总额</div>
                <div class="text_center"> <span class="fs_30">{{ formatNumberWithCommas(chinaEed)}}</span> 美元</div>
              </div>
            </div>

            <div class="mv_10">
              <el-button-group>
                <el-button size="mini" :type="name == 'el' ? 'primary': ''" @click="handleClickName('el')">二连口岸</el-button>
                <el-button size="mini" :type="name == 'hm' ? 'primary': ''" @click="handleClickName('hm')">边民互市</el-button>
              </el-button-group>
              <el-button-group class="ml_10">
                <el-button size="mini" :type="type == 'import' ? 'primary': ''" @click="handleClickType('import')">进口</el-button>
                <el-button size="mini" :type="type == 'export' ? 'primary': ''" @click="handleClickType('export')">出口</el-button>
              </el-button-group>
            </div>

            <div class="w_full h_full-200 border_radius_5">
              <div class="w_full h_40 lh_40 flex_row_between border_b_1_s_eee fw_bold">
                <div class="w_32_p text_right pr_10 border_r_1_s_eee">国家/地区</div>
                <div class="w_30_p text_center">进口总额</div>
                <div class="w_30_p text_center border_l_1_s_eee">出口总额</div>
              </div>

              <div class="w_full h_full-30 scroll_box_y overflow_y_auto">
                <div 
                  class="data_item w_full flex_row_between cursor" 
                  :class="{ 'bgc_fff': activeIndex == index }"
                  v-for="(item, index) in globalData" :key="index"
                  @click="clickItem(item, index)"
                >
                  <div 
                    class="w_32_p text_right p_5 border_r_1_s_eee" 
                    :class="{ 'fw_bold': activeIndex == index }">{{ item.country }}
                  </div>
                  <div 
                    class="w_30_p text_center p_5" 
                    :class="{ 'color_0874EF': activeIndex == index, 'color_ccc': type == 'import' }">{{ item.ied }}
                  </div>
                  <div 
                    class="w_30_p text_center p_5 border_l_1_s_eee" 
                    :class="{ 'color_d21e42': activeIndex == index, 'color_ccc': type == 'export' }">{{ item.eed }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w_full-600 h_full m_0_auto ph_10">
            <word-map-2 
              :heightLightArea="heightLightArea" 
            />
          </div>
        </div> 
      </div>
    </div>
</template>

<script>
  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components';

  import { getTradeData, chinaQueryHs6 } from '@/api/base/index.js'

  import { Message } from 'element-ui'


  export default {
    name: 'HomeBianMin',
    mixins: [defMixin, componentsMixin],
    data() {
      return {
        activeIndex: null,
        hsCode: '',

        name: 'hm',
        type: 'import',

        chinaIed: null,
        chinaEed: null,

        dataList: [],
        globalData: [],
        heightLightArea: [],
      }
    },
    computed: {

    },
    created() {

    },
    async mounted() {
      let hsCode = this.$route.params.hsCode
      this.hsCode = hsCode

      this.chinaQueryHs6(hsCode)

      this.getTradeData(hsCode)
    },
    destroyed() {

    },
    methods: {
      async chinaQueryHs6(hsCode) {
        let res = await chinaQueryHs6(hsCode)
        try {
          if (res.code == 1000) {
            this.chinaIed = res.data.ied
            this.chinaEed = res.data.eed
          }
        } catch(err) {
          console.log('获取中国进出口数据失败 : ', err);
        }
      },  

      async getTradeData(hsCode) {
        let params_hscode = this.$route.params.hsCode
        if (params_hscode != hsCode) {
          this.$router.replace({ path: `/global/${hsCode}`})
        }

        let res = await getTradeData(hsCode)
        try {
          this.dataList = res
          this.globalData = this.formatData(res, 'hm', 'import')
        } catch(err) {
          console.log('获取 边民互市、二连海关 数据失败 : ', err);
        }
      },

      handleClickName(name) {
        this.name = name

        this.activeIndex = null
        this.heightLightArea = []

        this.globalData = this.formatData(this.dataList, name, this.type)
      },

      handleClickType(type) {
        this.type = type

        this.activeIndex = null
        this.heightLightArea = []

        this.globalData = this.formatData(this.dataList, this.name, type)
      },

      formatData(resData, name, type) {
        let filterType = name == 'hm' ? 1 : 0

        if (resData == null || resData.length == 0) {
          return null;
        }

        let list = resData.filter(record => {
          return record.type == filterType;
        })

        return list.sort((a, b) => {
          // debugger
          if (type == 'import') {
            return b.eed - a.eed;
          } else {
           return b.ied - a.ied;
          }
        })
      },

      clearHs6Value() {
        this.hsCode = ''
        this.activeIndex = null
        this.dataList = []
        this.globalData = []
        this.heightLightArea = []
      },

      clickItem(item, index) {
        this.activeIndex = index

        this.heightLightArea = [item.country]
      },

      goToMyDsj2() {
        window.open('http://mydsj2.dayaar.com.cn/')
      },

      // 跳转页面
      goToPage(path) {
        this.$router.push(path)
      }
    }
  }
</script>

<style>
  .data_item:nth-child(even) {
    background-color: #fff;
  }
  .data_item:nth-child(odd) {
    background-color: #f6ddcf;
  }
</style>

